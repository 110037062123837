<template>
  <b-modal
  id="photocontrolModalEdit"
  title="Изменить фотоконтроль"
  size="lg"
  hide-footer no-enforce-focus
  :no-close-on-backdrop="true"
  @show="getPerformerId(),getstatus(),detectStartTime()"
  @close="closeModal()"
  @hidden="detectEndTime"
  >
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col cols="12">
            <label for="">Связка Водитель - Автомобиль: <span class="required">*</span></label>
            <b-input
            v-model="bodyPhotocontrol.perfoDriver" 
            disabled
            placeholder="Связка Водитель - Автомобиль"
            >
            </b-input>   
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="6">
            <b-button style="width: 100%" variant="primary" @click="getDriver()">
              Водитель
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button style="width: 100%" variant="primary" @click="getCar()">
              Автомобиль
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <label for="">Тип фотоконтроля: <span class="required">*</span></label>
            <b-input v-model="bodyPhotocontrol.type_photo_control_description" placeholder="Тип фотоконтроля" disabled class="select-size-md"></b-input>
          </b-col>
          <b-col cols="12" class="mt-2">
            <label for="">Дата, до которой нужно загрузит фото: <span class="required">*</span></label>
            <b-input type="date" disabled v-model="bodyPhotocontrol.start_date"></b-input>
          </b-col>
          <b-col class="mt-2" cols="12">
            <b-button style="width: 100%" variant="primary" @click="getPhoto()">
              Фото водителя и авто
            </b-button>
          </b-col>
          <b-col cols="12" class="mt-2">
            <label for="">Статус</label>
          <v-select
          v-model="bodyPhotocontrol.status_id" 
          :disabled="bodyPhotocontrol.has_photo == false"
          :reduce="option => option.id"
          placeholder="Статус"
          label="name" 
          :options="status" 
          class="select-size-md"
          >
          <template #no-status>
            <div>
              <span>
                Данные отсутствуют!
              </span>
            </div>
          </template>
          </v-select>
          </b-col>
          <b-col class="mt-2" cols="12">
            <label for="">История:</label>
            <b-textarea style="height: 150px;"
            v-model="bodyPhotocontrol.description"
            disabled
            >
            </b-textarea>
          </b-col>
          <b-col class="mt-2" cols="12">
            <label for="">Комментарий</label>
            <b-textarea style="height: 80px;"
            v-model="bodyPhotocontrol.comment"
            >
            </b-textarea>
          </b-col>
          <b-col cols="12" class="mt-2">
            <label for="">Дата нового фотоконтроль: <span class="required">*</span></label>
            <b-input type="date" v-model="bodyPhotocontrol.end_date"></b-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-1">
        <b-col class="d-flex justify-content-end">
            <b-button
            :disabled="submitButtonDisabled"
            @click="addPhotocontrol()"
            variant="primary">Ок</b-button>

            <b-button class="ml-1 mr-1" @click="closeModal()" variant="danger">Отмена</b-button>
        </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: ['id'],
  components: {
    vSelect,
    ToastificationContent
  },
  data() {
    return {
      error: '',
      formTime: {
        form_name: null,
        start_time: null,
        end_time: null,
      },
      submitButtonDisabled : false,
      data: [],
      status: [],
      performer_id: null,
      bodyPhotocontrol: {
        perfoDriver: null,
        description: null,
        type_photo_control_id: null,
        type_photo_control_name: null,
        type_photo_control_description: null,
        performer_assign_p_c_id: null,
        status_id: '',
        comment: '',
        p_c_comment_id: null,
        end_date: '',
        start_date: null,
        has_photo: null,
      },
    }
  },
  methods: {
    detectEndTime() {
      this.formTime.end_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
      this.$http.post('time-form', this.formTime)
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      }) 
    },
    detectStartTime() {
      this.formTime.form_name = 'photocontrolEditForm'
      this.formTime.start_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
    },
    getPhoto() {
      this.$router.push({name:'driversPhotocontrol', query:{driver_id: this.data[0].performer_id}})
      this.$store.commit('draggableTab/ADD_TAB', ["Фото " + this.data[0].performer_id, `/driver/photo`, this.data[0].performer_id])
    },
    getDriver() {
        this.$router.push({name: 'infoDriver', query:{filter: this.data[0].performer_id}})
        this.$store.commit('draggableTab/ADD_TAB', ['Водитель ' + this.data[0].performer_id, `/infoDriver`, this.data[0].performer_id  ])
      },
    getCar() {
      console.log(this.data, 'egege');
      this.$router.push({ name: "carInfo", query: { filter: this.data[0].model.id } });
        this.$store.commit("draggableTab/ADD_TAB", [
          "Автомобиль " + this.data[0].model.id,
          `/driver/carInfo`,
          this.data[0].model.id,
        ]);
    },
    getPerformerId(){
      this.$http
      .get(`/photo-control/performer-assign/search?filter_id=${this.id}`)
      .then(res=>{
        this.bodyPhotocontrol.performer_assign_p_c_id = this.id
        this.performer_id = res.data[0].performer_id
        this.bodyPhotocontrol.status_id = res.data[0].performer_photo_control_status_id
        this.bodyPhotocontrol.description = res.data[0].description
        this.bodyPhotocontrol.start_date = res.data[0].start_date
        this.bodyPhotocontrol.end_date = res.data[0].end_date
        this.data = res.data
        this.data.forEach(el => {
          let bundleDriver = this.data[0].division + ' ' + '-' + ' ' + el.performer_fio + ' ' + '-' + ' ' +el.performer_car
          this.bodyPhotocontrol.perfoDriver = bundleDriver
        }); 
        this.bodyPhotocontrol.type_photo_control_name = res.data[0].type_photo_control_name
        this.bodyPhotocontrol.type_photo_control_description = res.data[0].type_photo_control_description
        this.bodyPhotocontrol.type_photo_control_id = res.data[0].type_photo_control_id
        this.bodyPhotocontrol.has_photo = res.data[0].has_photo
      })  
    },
    getstatus() {
      this.$http
        .get('photo-control/statuses')
        .then(res => {
          this.status = res.data
          console.log(this.status);
        })
    },
    addPhotocontrol(){
      this.submitButtonDisabled = true
      let bodyPhoto = {
        performer_id: this.performer_id,
        performer_assign_p_c_id: this.bodyPhotocontrol.performer_assign_p_c_id,
        status_id: this.bodyPhotocontrol.status_id,
        comment: this.bodyPhotocontrol.comment,
        end_date: this.bodyPhotocontrol.end_date
      }
      this.$http
      .post(`photo-control/performer/${this.performer_id}`, bodyPhoto)
      .then(res => {
        this.submitButtonDisabled = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
              title: 'Успех!',
              icon: 'CheckIcon',
              variant: 'success',
              text: `Фотоконтроль назначен`,
          },
        })
        this.closeModal()
        this.$emit('refresh')
      })
      .catch((error) => {
        if(error.response) {
          
          if(error.response.data.errors.end_date) {
            this.error = error.response.data.errors.end_date[0]
          }
        }else {
          this.error = ''
        }
        this.submitButtonDisabled = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
              title: 'Ошибка!',
              icon: 'XIcon',
              variant: 'danger',
              text: `Некоректные данные: ${this.error}`,
          },
        })
      })
    },
    closeModal(){
      this.$bvModal.hide('photocontrolModalEdit')
      this.bodyPhotocontrol = {
        status_id :'',
        type_photo_control_id: '',
        start_date: new Date().toISOString().split('T')[0],
        end_date: new Date().toISOString().split('T')[0],
        perfoDriver: null,
        description: null,
        type_photo_control_id: null,
        type_photo_control_name: null,
        type_photo_control_description: null,
        performer_assign_p_c_id: null,
        comment: null,
        p_c_comment_id: null,
        end_date: null,
        start_date: null,
        has_photo: null,
      }
      this.data = []
      this.status = []
      this.$bvModal.hide('photocontrolModal')
    }
  }, 
}
</script>

<style scoped>
.required{ 
  color: red;
}
</style>