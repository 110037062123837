<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-photocontrol :photocontrols="photocontrols" @editForm="editForm"></table-photocontrol>
        </div>
        <modal-photocontrol-edit :id="id"  @refresh="refresh"></modal-photocontrol-edit>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
<script>
import tablePhotocontrol from "@/views/component/Table/tablePhotocontrol.vue"
import modalPhotocontrolEdit from "@/views/component/Modal/ModalPhotocontrol/modalPhotocontrolEdit.vue"

export default {
    components: {
        tablePhotocontrol,
        modalPhotocontrolEdit,
    },
    data() {
        return {
            id: '',
            showPreloader: false,
            photocontrols: [],
            driver_id: this.$route.query.driver_id,
            fields: [
                { key: 'id', label: 'Id'},
                { key: 'performer_photocontrolstatusname', label: 'Статус'},
                { key: 'division_photo', label: 'Подразделение'},
                { key: 'type_description', label: 'Тип ФК' },
                { key: 'created_by_fio', label: 'Кто создал' },
                { key: 'has_photo', label: 'Нал. фото' },
                { key: 'performer', label: 'Водитель' },
                { key: 'car', label: 'Автомобиль' },
                { key: 'car-class', label: 'Класс авто' },
            ],
        }
    },
    mounted() {
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            if (val){
                this.$http
                .get(`${this.$route.name}`, {params: param})
                .then(res =>{
                    this.photocontrols = res.data
                    this.$store.commit('pageData/setdataCount', this.photocontrols.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    },
    methods: {
        refresh() {
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            this.showPreloader = true
            this.$http
            .get(`${this.$route.name}`, {params: param})
            .then(res => {
                this.photocontrols = res.data
                this.showPreloader = false
                this.$store.commit('REFRESH_DATA', false)
            }).catch(err => {
                console.log(err);
            })
        },
        editForm(id) {
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openFilter() {
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            if(this.driver_id) {
                param.performer_id = this.driver_id
            }
            let arrayRoutes = []
            allRoutes.forEach(element => {
            arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, { params: param }).then(res => {
                        this.photocontrols = res.data
                        this.$store.commit('pageData/setdataCount', this.photocontrols.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            } else if (arrayRoutes.includes(this.$route.path) == false) {
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
        openModal() {
            this.$bvModal.show('photocontrolModalEdit')
        },
        sendToParent(tableData) {
            this.photocontrols = tableData
            this.$store.commit('pageData/setdataCount', this.photocontrols.length)
        },
    },
}
</script>