<template>
  <div>
      <b-row>
          <b-col cols="12">
              <b-card class="my-card m-0">
                  <b-table 
                  striped
                  small
                  id="myTables"
                  bordered
                  selectable
                  selected-variant="primary"
                  select-mode="single"
                  responsive="sm"
                  :items="photocontrols"
                  :fields="fields"
                  :tbody-tr-class="rowClass"
                  @row-dblclicked="editForm"
                  @row-clicked="clicked"
                  head-row-variant="secondary"
                  >
                  <template #cell(checkbox)="row">
                      <b-form-checkbox
                      v-model="row.detailsShowing"
                      plain
                      class="vs-checkbox-con"
                      @change="row.toggleDetails"
                      >
                      </b-form-checkbox>
                  </template>
                  <template #cell(has_photo)="data">
                    <div v-if="data.item.has_photo">
                        Да
                    </div>
                    <div v-else>
                      Нет
                    </div>
                  </template>
                  <template #cell(files)="data">
                      <span class="reference-field" @dblclick="getFiles(data)">
                        (***)
                      </span>
                  </template>
                  <template #cell(driver)="data">
                      <span class="reference-field" @dblclick="getDriver(data)">
                        {{ data.item.model ? data.item.model.first_name + ' ' + data.item.model.last_name : '' }}
                      </span>
                  </template>
              </b-table>
          </b-card>
      </b-col>
      </b-row>
  </div>
</template>

<script>
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/css/bootstrap.css'
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: ['photocontrols'],
  components: {
      ToastificationContent
  },
  data() {
      return {
          id: '',
          fields: [
          { key: 'checkbox', label: '', thStyle: {width: '30px'}},
          { key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
          { key: 'division', label: 'Подразделения', sortable: true, thStyle: {width: '150px'}},
          { key: 'type_photo_control_name', label: 'Тип фотоконтроля', sortable: true, thStyle: {width: '150px'}},
          { key: 'performer_photo_control_status_name', label: 'Статус', sortable: true, thStyle: {width: '150px'}},
          { key: 'created_by_fio', label: 'Создал', sortable: true, thStyle: {width: '150px'} },
          { key: 'start_date', label: 'Создан', sortable: true, thStyle: {width: '150px'} },
          { key: 'end_date', label: 'Пройти до', sortable: true, thStyle: {width: '150px'} },
          { key: 'has_photo', label: 'Наличие фото', sortable: true, thStyle: {width: '150px'} },
          { key: 'driver', label: 'Водитель', sortable: true, thStyle: {width: '150px'} },
          { key: 'performer_car', label: 'Автомобиль', sortable: true, thStyle: {width: '150px'} },
          { key: 'files', label: 'Файлы', sortable: true, thStyle: {width: '150px'} },
          // { key: 'class_car', label: 'Файлы автомобиля', sortable: true, thStyle: {width: '150px'} },
          { key: 'car_drivers', label: 'Сосотояние автомобиля', sortable: true, thStyle: {width: '150px'} },
          { key: 'dop_options', label: 'Класс автомобиля', sortable: true, thStyle: {width: '150px'}, },
          { key: 'history', label: 'Год автомобиля', sortable: true, thStyle: {width: '150px'} },
          { key: 'created_by_fio', label: 'Создавший сотрудник', sortable: true, thStyle: {width: '150px'} },
          { key: 'photo_controls', label: 'Дата изменения', sortable: true, thStyle: {width: '150px'} },
          { key: 'created_at', label: 'Дата загрузки фото', sortable: true, thStyle: {width: '150px'} },
          { key: 'description', label: 'Комментарий', sortable: true, thStyle: {width: '150px'} },
          ],
      }
  },
  mounted(){
      resizeable()
  },
  methods: {
      editForm(item){
          this.$emit('editForm', item.id)
      },
      getFiles(data) {
        this.$router.push({name: 'driversPhotocontrol', query:{driver_id: data.item.performer_id,}})
        this.$store.commit('draggableTab/ADD_TAB', ['Водитель ' + data.item.performer_id, `/driver/photo`, data.item.performer_id])
      },
      getDriver(data) {
        this.$router.push({name: 'infoDriver', query:{filter: data.item.performer_id}})
        this.$store.commit('draggableTab/ADD_TAB', ['Водитель ' + data.item.performer_id, `/infoDriver`, data.item.performer_id  ])
      },
      clicked(item, index, event){
        this.$store.state.filterPlus.tableIndex = index
            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                return;
            }

            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;
            this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
            cellSelect()
        },
        rowClass(item, type) {
        if (!item || type !== 'row') return
        if (item.has_photo === false) return 'table-danger'
        else if (item.has_photo === true) return 'table-white'
        else return 'table-secondary'
      },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.my-card .card-body{
  padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
.my-card{
  overflow: auto; 
  height: 530px;
  }
}
table{
  outline: none; 
  table-layout: fixed; 
  -webkit-user-select: none;
  overflow: hidden;
  position: relative;
}
tr, td, th{
  padding: 0;
}
td, th{ 
  width: 100px; 
  white-space: nowrap; 
  overflow: hidden;  
  text-align: left; 
  box-sizing: border-box; 
  min-width: 60px;
  }  
.my-danger{
  background-color: #f9cfcf;
}  
.my-success{
  background-color: #8fe2b4;
} .my-secondary{
  background-color: #dcdddf;
} .my-warning{
  background-color: #ffcd9d;
}   
</style>